// normalize CSS across browsers
import "./src/css/normalize.css"
import "./src/css/style.scss"
import "./src/css/mixin.scss"

window.onload = function () {
  const theme = window.localStorage.getItem("theme")
  if (theme) {
    document.documentElement.setAttribute("color-theme", theme)
  }
}
